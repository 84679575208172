import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderhistory } from '../../redux/Slice/Seller/OrderSlice';
import { useNavigate } from 'react-router-dom';

const OrderHistory = () => {
    const { orderData, orderStatus } = useSelector(state => state.orderhistory);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(orderhistory());
    }, [dispatch]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };


    return (
        <div className="container mt-4 mx-4">
            <h2 className="text-center mb-4 text-3xl font-bold animate-pulse">Order History</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {orderData && orderData.map((order) => (
                    <div key={order.uid} className="bg-white p-4 rounded-lg shadow-md hover:scale-105 transition duration-300 ease-in-out flex">
                        <div className="w-24 h-24 flex-shrink-0">
                            <img
                                src={order.product_image}
                                alt={order.productName}
                                className="w-full h-full object-cover rounded-lg"
                            />
                        </div>
                        <div className="flex flex-col ml-4">
                            <h5 className="text-lg font-bold">{order.productName.split(" ").slice(0, 3).join(" ")}</h5>
                            <p className="text-gray-600 text-sm">Date: {formatDate(order.created_at)}</p>
                            <p className="text-gray-600 text-sm">Total: ₹{order.total}</p>
                            <p className="text-gray-600 text-sm">Status: {order.payment_status}</p>
                            <button
                                className="bg-primeColor text-white text-sm rounded-md font-bodyFont w-full md:w-[120px] h-[30px] hover:bg-white hover:text-black hover:border-2 border-black duration-300 font-bold mt-4"
                                onClick={() => navigate("/track-order", { state: { product: order } })}
                            >
                                Track Order
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default OrderHistory;
