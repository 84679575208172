import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
// Function to retrieve token from local storage
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};
// 5
export const addColor = createAsyncThunk(
    "admin/addColor", // Unique action type for getProduct
    async (data) => {
        // Retrieve token (temporary)
        const token = await getTokenFromLocalStorage();

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/color/add`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(data),
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.status) {
                handleApiSuccess(final.message);
            }
            else {
                handleApiError(final.errors);
            }
            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            handleApiError(error, "failed");
            // console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);
// 6
export const getColor = createAsyncThunk(
    "admin/getColor", // Unique action type for getProduct
    async (data) => {
        const token = await getTokenFromLocalStorage();
        
        // const token = '23|3q3e9wLHiwjFmEQWxY8DX6mn8IHf4o9tDy8juyMQf54cc898';

        try {
            // Make API request to get products
            const res = await fetch(`${apiUrl}admin/color`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                // body: JSON.stringify(data), 
            });

            // Parse response
            const final = await res.json();

            // Display message if status is true
            if (final.data.status) {
                alert(final.data.message);
            }

            // Return data
            return final.data;
        } catch (error) {
            // Handle errors
            console.error("Error getting products:", error);
            throw error; // Throw error to be caught by rejected action
        }
    }
);

// Define product slice
const AdminColorSlice = createSlice({
    name: "color",
    initialState: {
        colorData: [],
        isLoader: false,
        isError: false,
        colorStatus: 'pending',
    },
    extraReducers: (builder) => {
        //  1
        builder
            .addCase(addColor.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(addColor.fulfilled, (state, action) => {
                state.colorStatus = 'success';
                state.isLoader = false;
                state.colorData = action.payload;
            })
            .addCase(addColor.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
        // 2
        builder
            .addCase(getColor.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(getColor.fulfilled, (state, action) => {
                state.colorStatus = 'success';
                state.isLoader = false;
                state.colorData = action.payload;
            })
            .addCase(getColor.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export product reducer
export default AdminColorSlice.reducer;
