import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CompareProductList } from "../redux/Slice/Seller/CompareSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const CompareList = () => {
    const dispatch = useDispatch();
    const [timestamp, setTimestamp] = useState(new Date());
    const [id, setId] = useState([]);
    const { compareData } = useSelector((state) => state.compare) || {};

    useEffect(() => {
        if (id.length > 0) {
            dispatch(CompareProductList({ id }));
        }
    }, [dispatch, id]);


    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimestamp(new Date());
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const removestorage = (ids) => {
        let idArray = id ? id.split(',') : [];
        const updatedIds = idArray.filter(id => id !== ids);
        localStorage.setItem('compare_id', updatedIds.join(','));
        setId(updatedIds.join(','));
        dispatch(CompareProductList({ id: updatedIds }));
    };


    return (
        <div className="compare-list px-4 sm:px-8 md:px-16 lg:px-20 xl:px-24">
            {compareData && compareData.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                    {compareData.map((product, index) => (
                        <div key={index} className="card">
                            <div className="card-body">
                                <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    className="h-5 w-5"
                                    onClick={() => removestorage(product.id)}
                                />
                                <img
                                    src={product.imageUrl}
                                    alt={product.name}
                                    className="w-full h-auto rounded-lg"
                                />
                                <p>{product.name}</p>
                                <p>Color : {product.color}</p>
                                <p>Price : ${product.price}</p>
                                <p>Category : {product.category}</p>
                                <p>{product.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center py-8">No products to compare</div>
            )}
        </div>
    );
};

export default CompareList;
