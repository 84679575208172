import React from 'react';
import AdminLayout from './AdminLayout';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const Dashboard = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        // dispatch(fetchOrderHistory());
    }, []);
    return (
        <AdminLayout>
            <div className="App">
                <div className="flex w-full h-1/2">
                    <div className="flex w-full h-screen">
                        <div className="flex-1 bg-blue-500 h-1/3">
                            <label>Total Orders</label>
                        </div>
                        <div className="flex-1 bg-green-500 h-1/3">
                            <label>Order Pending</label>
                        </div>
                        <div className="flex-1 bg-red-500 h-1/3">
                            <label>Order Cancel</label>
                        </div>
                        <div className="flex-1 bg-yellow-500 h-1/3">
                            Order Complete
                        </div>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Dashboard;
