import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

// Define the async thunk for fetching product year data
export const ProductYear = createAsyncThunk(
    "product/fetchYearData", // Updated the action type string
    async () => {
        const res = await fetch(`${apiUrl}product/year`, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                Accept: "*/*",
            },
        });
        const final = await res.json();
        return final.product;
    }
);

// Create the slice
const ProductofYearSlice = createSlice({
    name: 'product',
    initialState: {
        yearData: null,
        isLoader: false,
        isError: false,
        yearStatus: 'idle', // Initial status should be 'idle' rather than 'pending'
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ProductYear.pending, (state) => {
                state.isLoader = true;
                state.yearStatus = 'loading';
            })
            .addCase(ProductYear.fulfilled, (state, action) => {
                state.yearData = action.payload;
                state.isLoader = false;
                state.yearStatus = 'succeeded';
            })
            .addCase(ProductYear.rejected, (state) => {
                state.isLoader = false;
                state.isError = true;
                state.yearStatus = 'failed';
            });
    },
});

export default ProductofYearSlice.reducer;
