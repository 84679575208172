import { useState, useEffect } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import AdminLayout from "./AdminLayout";
import { getType } from "../redux/Slice/Admin/ProductTypeSlice";
import { getCategory } from "../redux/Slice/Admin/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editProduct } from "../redux/Slice/Admin/ProductSlice";
const EditAdminProduct = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { categoryData, categoryStatus } = useSelector(
        (state) => state.category
    );
    const { typeStatus, productType } = useSelector((state) => state.type);
    const searchParams = new URLSearchParams(location.search);
    const data = Object.fromEntries(searchParams.entries());
    const [formData, setFormData] = useState({
        id: data?.id,
        name: data?.name,
        price: data?.price,
        discount: data?.discount,
        image: "",
        description: data?.description,
        image_url: data?.images,
        image1: data?.images1,
        image2: data?.images2,
        image3: data?.images3,
        type: data?.type_id,
        category: data?.category_id,
        qty: data?.quantity,
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        // setImage(file);
        if (file) {
            // Update the image property with the selected file
            setFormData({
                ...formData,
                image: file,
            });
        }
    };
    const handleImageChange1 = (e) => {
        const file1 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image1: file1, // Assuming 'image' is the name of the field in formData
        });
    };
    // 2
    const handleImageChange2 = (e) => {
        const file2 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image2: file2, // Assuming 'image' is the name of the field in formData
        });
    };
    // 3
    const handleImageChange3 = (e) => {
        const file3 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image3: file3, // Assuming 'image' is the name of the field in formData
        });
    };
    const handleSubmit = async (e) => {
        dispatch(editProduct(formData));
        navigate("/admin/product");
    };
    useEffect(() => {
        dispatch(getCategory());
        dispatch(getType());
    }, []);

    return (
        <AdminLayout>
            <form>
                <div className="space-y-12 w-8/12 m-auto">
                    <div className="pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Product Type
                                </label>
                                <div className="mt-2">
                                    <select
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {productType.map((option) => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Category
                                </label>
                                <div className="mt-2">
                                    <select
                                        name="category"
                                        value={formData.category}
                                        onChange={handleChange}
                                        className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    >
                                        {categoryData.map((data) => (
                                            <option
                                                key={data.id}
                                                value={data.id}
                                            >
                                                {data.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Price
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        autoComplete="last_name"
                                        className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Discount
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="discount"
                                        name="discount"
                                        type="number"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Quantity
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="qty"
                                        type="number"
                                        value={formData.qty}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            
                            
                            <div className="lg:col-span-6">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Description
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="description"
                                        type="text"
                                        value={formData.description}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Image
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        multiple
                                        onChange={handleImageChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <img
                                        src={formData.image_url}
                                        alt={formData.name}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Image1
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image1"
                                        type="file"
                                        multiple
                                        onChange={handleImageChange1}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <img
                                        src={formData.image1}
                                        alt={formData.name}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Image2
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image2"
                                        type="file"
                                        multiple
                                        onChange={handleImageChange2}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <img
                                        src={formData.image2}
                                        alt={formData.name}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Image3
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image3"
                                        type="file"
                                        multiple
                                        onChange={handleImageChange3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <div className="mt-2">
                                    <img
                                        src={formData.image3}
                                        alt={formData.name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center gap-x-6">
                    <button
                        type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        onClick={handleSubmit}
                    >
                        Save
                    </button>
                    <a
                        href="/admin/product/category"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Back
                    </a>
                </div>
            </form>
        </AdminLayout>
    );
};

export default EditAdminProduct;
