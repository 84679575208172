import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

// Helper function to store the token in local storage
const getTokenFromLocalStorage = async () => {
  return localStorage.getItem("token");
};
export const allproduct = createAsyncThunk(
  "allproduct",
  async (category = null) => { // Accept category as an optional parameter
    const token = await getTokenFromLocalStorage();

    // Construct the URL based on whether category is provided or not
    let apiUrlWithCategory = apiUrl + 'product';
    if (category) {
      apiUrlWithCategory += `/${category}`;
    }

    const res = await fetch(apiUrlWithCategory, { // Use apiUrlWithCategory in the fetch call
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);
    } else {
      // If the login is successful, store the token in local storage

    }
    return final.data;
  }
);

// 2 
export const searchProduct = createAsyncThunk(
  "admin/searchProduct",
  async (credentials) => {
    const token = await getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}product/search/${credentials}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Authorization: `Bearer ${token}`,
      },
      // body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);
    } else {
      // If the login is successful, store the token in local storage
      // setTokenToLocalStorage(final.data.token);
    }
    return final.data;
  }
);
const ProductListSlice = createSlice({
  name: "ProductList",
  initialState: {
    data: [],
    isLoader: false,
    isError: false,
    status: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(allproduct.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(allproduct.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;

    });
    builder.addCase(allproduct.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    // 2
    builder.addCase(searchProduct.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(searchProduct.fulfilled, (state, action) => {
      state.status = "success";
      state.isLoader = false;
      state.data = action.payload;
    });
    builder.addCase(searchProduct.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ProductListSlice.reducer;
