import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminLayout from "./AdminLayout";
import { getColor } from "../redux/Slice/Admin/AdminColorSlice";
import { Link } from "react-router-dom";
import { fetchOrderStatus, editOrderStatus } from "../redux/Slice/Admin/OrderHistorySlice";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const AdminOrderStatus = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { orderHistory, historyStatus } = useSelector((state) => state.adminOders);
    const [modalVisible, setModalVisible] = useState(false);
    const [editData, setEditData] = useState(null);
    const [orderDate, setOrderDate] = useState();
    const [deliveryDate, setdeliveryDate] = useState();
    useEffect(() => {
        dispatch(fetchOrderStatus());
    }, [dispatch]);

    const EditAdminOrderStatus = (data) => {
        setEditData(data);
        setModalVisible(true);
    };

    const handleStatusChange = (productId, newStatus) => {
        dispatch(editOrderStatus({ productId, newStatus, orderDate, deliveryDate }));
    };

    const handleModalClose = () => {
        setModalVisible(false);
        setEditData(null);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (editData) {
            dispatch(editOrderStatus({
                productId: editData.id,
                newStatus: editData.status,
                orderDate: orderDate,
                deliveryDate: deliveryDate,
            }));
            handleModalClose();
        }
    };

    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-body">
                    <table className="productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Status</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {historyStatus === 'success' && (
                                orderHistory.map(product => (
                                    <tr key={product.id}>
                                        <td className="text-center">{product.id}</td>
                                        <td className="text-center">{product.productname}</td>
                                        <td>
                                            <select
                                                defaultValue={product.status} // Set default selected value
                                                onChange={(e) => handleStatusChange(product.id, e.target.value)}
                                            >
                                                <option value="pending" disabled={(product.status === 'cancel' || product.status === 'complete' || product.status === 'shipped' || product.status === 'picked')}>pending</option>
                                                <option value="picked" disabled={(product.status === 'shipped' || product.status === 'complete' || product.status === 'cancel')}>picked</option>
                                                <option value="shipped" disabled={(product.status === 'complete' || product.status === 'cancel')}>shipped</option>
                                                <option value="complete" disabled={(product.status === 'cancel')}>complete</option>
                                                <option value="cancel" disabled={(product.status === 'complete' || product.status === 'shipped' || product.status === 'picked')}>cancel</option>
                                            </select>
                                        </td>
                                        <td className="text-center" onClick={() => EditAdminOrderStatus(product)}>
                                            <a href="#">Edit</a>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Modal */}
            {modalVisible && editData && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75 z-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <h2 className="text-xl font-semibold mb-4">Edit Order Status</h2>
                        <form onSubmit={handleFormSubmit}>
                            <div className="mb-4">
                                <label htmlFor="productname" className="block text-gray-700">Order Date</label>
                                <input
                                    type="date"
                                    name="orderdate"
                                    value={editData.orderdate}
                                    onChange={(e) => setOrderDate(e.target.value)}

                                    className="border border-gray-300 p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="productname" className="block text-gray-700">Delivert  Date</label>
                                <input
                                    type="date"
                                    name="deliveryDate"
                                    value={editData.deliveryDate}
                                    onChange={(e) => setdeliveryDate(e.target.value)}
                                    className="border border-gray-300 p-2 w-full"
                                />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="status" className="block text-gray-700">Status</label>
                                <select
                                    id="status"
                                    value={editData.status}
                                    onChange={(e) => setEditData({ ...editData, status: e.target.value })}
                                    className="border border-gray-300 p-2 w-full"
                                >
                                    <option value="pending" disabled={(editData.status === 'cancel' || editData.status === 'complete' || editData.status === 'shipped' || editData.status === 'picked')}>pending</option>
                                    <option value="picked" disabled={(editData.status === 'shipped' || editData.status === 'complete' || editData.status === 'cancel')}>picked</option>
                                    <option value="shipped" disabled={(editData.status === 'complete' || editData.status === 'cancel')}>shipped</option>
                                    <option value="complete" disabled={(editData.status === 'cancel')}>complete</option>
                                    <option value="cancel" disabled={(editData.status === 'complete' || editData.status === 'shipped' || editData.status === 'picked')}>cancel</option>
                                </select>
                            </div>
                            <div className="flex justify-end">
                                <button type="button" onClick={handleModalClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
                                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </AdminLayout>
    );
};

export default AdminOrderStatus;
