import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const BestSellers = () => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.ProductList);
  useEffect(() => {
    dispatch(allproduct());
  }, []);


  return (
    <div className="w-full pb-20">
      <Heading heading="Our Bestsellers" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-5">
        {data.map(product => (
          product.type == 2 ? (
            <Product
              key={product.id}
              _id={product.id}
              img={product.images}
              img1={product.images1}
              img2={product.images2}
              img3={product.images3}
              productName={product.name}
              price={product.price}
              color={product.color}
              badge={false}
              des={product.description}
            />
          ) : null
        ))}
      </div>
    </div>
  );
};

export default BestSellers;
