import React, { useState } from 'react';
import AdminLayout from './AdminLayout';
import { useDispatch } from "react-redux";
import { addBanner } from '../redux/Slice/Admin/AdminBannerSlice';

const Banner3image = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: '',
        image: null, // Updated to store file object
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageUpload = (e) => {
        const files = e.target.files;
        setFormData({
            ...formData,
            image: files[0], // Store the first selected file
        });
    };

    const handleSubmit = () => {
        // Dispatch action with formDataconso
        
        dispatch(addBanner(formData));
    };

    return (
        <AdminLayout>
            <form className="flex flex-col sm:flex-row sm:flex-wrap">
                <div className="flex flex-col w-full sm:w-1/2 sm:pr-4">
                    <label htmlFor="name" className="text-sm font-medium text-gray-900">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={handleChange}
                        className="block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-1"
                    />
                </div>
                <div className="flex flex-col w-full sm:w-1/2 mt-4 sm:mt-0">
                    <label htmlFor="image" className="text-sm font-medium text-gray-900">
                        Image
                    </label>
                    <input
                        type="file"
                        name="image"
                        id="image"
                        onChange={handleImageUpload}
                        className="block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-1"
                    />
                </div>
                <button
                    type="button"
                    onClick={handleSubmit}
                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-4"
                >
                    Save
                </button>
            </form>

        </AdminLayout>
    );
}

export default Banner3image;
