import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { getType } from "../redux/Slice/Admin/ProductTypeSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const ProductType = () => {
    const dispatch = useDispatch();
    const { typeStatus, productType } = useSelector((state) => state.type);
    useEffect(() => {
        dispatch(getType());
        
    }, [])
    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-header">
                    <Link
                        to="/admin/product/type/add"
                        className="card rounded-md bg-blue-600 px-3 p-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 float-end mb-2"
                    >
                        Add Type
                    </Link>
                </div>
                <div className="card-body">
                    <table className=" productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {typeStatus == 'success' && productType.map(product => (
                                <tr key={product.id}>
                                    <td className="text-center">{product.id}</td>
                                    <td className="text-center">{product.name}</td>
                                    <td className="text-center"><a href="">Edit</a></td>
                                </tr>
                            ))}
                            {typeStatus != 'success' && (
                                <tr>
                                    <td colSpan="3" className="text-center">Loading...</td>
                                </tr>
                            )}
                            {/* Optionally, handle other statuses like 'loading', 'error', etc. */}
                        </tbody>


                    </table>
                </div>
            </div>
        </AdminLayout>

    )
}
export default ProductType;