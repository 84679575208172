import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allproduct } from "../redux/Slice/Seller/ProductListSlice";
import { CompareProductList } from "../redux/Slice/Seller/CompareSlice";

const Compare = () => {
    const dispatch = useDispatch();
    const [timestamp, setTimestamp] = useState(new Date());
    const { data } = useSelector((state) => state.ProductList);
    const [comparedProducts, setComparedProducts] = useState([]);

    useEffect(() => {
        dispatch(allproduct());
    }, [dispatch]);

    const compareId = useCallback((id) => {
        const existingIds = new Set(comparedProducts);
        if (existingIds.has(id)) {
            existingIds.delete(id);
        } else {
            existingIds.add(id);
        }
        setComparedProducts([...existingIds]);
        localStorage.setItem('compare_id', JSON.stringify([...existingIds]));
        dispatch(CompareProductList({ id: [...existingIds] }));
    }, [comparedProducts, dispatch, setComparedProducts]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimestamp(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);



    return (
        <div className="w-full pb-20">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
                {data && data.length > 0 ? (
                    data.map((product) => (
                        <div key={product.id} className="flex flex-col gap-5">
                            <label className="flex-col items-center">
                                <input
                                    type="checkbox"
                                    className="form-control"
                                    onClick={() => compareId(product.id)}
                                />
                                <span className="ml-2">Compare</span>

                                {product.images && (
                                    <img src={product.images} alt={product.name} />
                                )}
                            </label>
                            <div className="flex flex-col gap-5">
                                {product.name}
                            </div>
                            {/* Add more product details as needed */}
                        </div>
                    ))
                ) : (
                    <div>No products available</div>
                )}
            </div>
        </div>
    );
};

export default Compare;
