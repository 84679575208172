import { useState } from "react";
import AdminLayout from "./AdminLayout";
import { getBanner,updateBanner } from "../redux/Slice/Admin/AdminBannerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Link } from "react-router-dom";

// Modal component for editing banner
const EditBannerModal = ({ isOpen, onClose, banner }) => {
  const dispatch = useDispatch();
  const [editData, setEditData] = useState({
    name: '',
    image: null, // Updated to store file object
  });
  useEffect(() => {
    setEditData({
      id:banner?.id,
      name:banner?.name,
      image:banner?.image,
    })
  },[banner]);

  const edithandleChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData,
      [name]: value,
    });
  };
  const edithandleImageUpload = (e) => {
    const files = e.target.files;
    setEditData({
        ...editData,
        image: files[0], // Store the first selected file
    });
};

const editHandleSubmit = () => {
  dispatch(updateBanner(editData));
};


  return (
    // Modal markup goes here
    <div
      className={`fixed top-0 left-0 w-full h-full flex items-center justify-center ${isOpen ? "" : "hidden"
        }`}
    >
      <div
        className="bg-white rounded-lg p-8 w-96"
        style={{ background: "#dcdcdc" }}
      >
        <label htmlFor="name" className="text-sm font-medium text-gray-900">
          Name
        </label>
        <input
          type="text"
          name="name"
          id="name"
          onChange={edithandleChange}
          value={editData?.name}
          className="block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-1"
        />
        <label htmlFor="name" className="text-sm font-medium text-gray-900">
          Image
        </label>
        <input
          type="file"
          name="name"
          id="name"
          onChange={edithandleImageUpload}
          // value={banner?.name}
          className="block w-full rounded-md border py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-1"
        />
        <img src={banner?.image} alt={banner?.name} className="mt-4" />
        {/* Additional fields for editing */}
        {/* Close button */}
        <button
          onClick={onClose}
          className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Close
        </button>
        <button
                    type="button"
                    onClick={editHandleSubmit}
                    className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-600 focus:outline-none mt-4"
                >
                    Save
                </button>


      </div>
    </div>
  );
};

const Banner3imageList = () => {
  const dispatch = useDispatch();
  const { bannerdata, bannerStatus } = useSelector(
    (state) => state.adminbanner
  );
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);

  useEffect(() => {
    dispatch(getBanner());
  }, [dispatch]);

  const handleEditClick = (banner) => {
    setSelectedBanner(banner);
    setIsEditModalOpen(true);
  };

  return (
    <AdminLayout>
      <div className="card mt-3">
        {/* Add Product button */}
        <div className="card-header">
          <Link
            to="/admin/add/product"
            className="card rounded-md bg-blue-600 px-3 p-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 float-end mb-2"
          >
            Add Product
          </Link>
        </div>
        {/* Banner list */}
        <div className="card-body">
          <table className="productTable w-full border border-collapse mt-0">
            <thead>
              <tr>
                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Image</th>
                <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
              </tr>
            </thead>
            <tbody>
              {bannerStatus === "success" ? (
                bannerdata &&
                bannerdata.length > 0 &&
                bannerdata.map((banner) => (
                  <tr key={banner.id}>
                    <td className="text-center">{banner.id}</td>
                    <td className="text-center">{banner.name}</td>
                    <td className="text-center">
                      <img src={banner.image} width="100" alt={banner.name} />
                    </td>
                    <td className="text-center">
                      {/* Edit button */}
                      <button
                        onClick={() => handleEditClick(banner)}
                        className="text-blue-600 hover:text-blue-800 mr-2"
                      >
                        Edit
                      </button>
                      {/* Delete button */}
                      <button className="text-red-600 hover:text-red-800">
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      {/* Edit banner modal */}
      <EditBannerModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        banner={selectedBanner}
      />
    </AdminLayout>
  );
};

export default Banner3imageList;
