import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = async() => {
  return localStorage.getItem("token");
};

export const fetchColordata = createAsyncThunk(
  "fetchColordata",
  async () => {
    
    const token = await getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}colors`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const final = await res.json();
    
    return final.data;
  }
);

const ColorSlice = createSlice({
  name: "front_color",
  initialState: {
    colors: null,
    isLoader: false,
    isError: false,
    colorsstatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchColordata.pending, (state) => {
      state.isLoader = true;
      state.bannerstatus = "pending";
    });
    builder.addCase(fetchColordata.fulfilled, (state, action) => {
      state.isLoader = false;
      state.colorsstatus = "success";
      state.colors = action.payload;
    });
    builder.addCase(fetchColordata.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
      state.colors = null;
      state.colorsstatus = "rejected";
    });
  },
});

export default ColorSlice.reducer;
