import { useState } from "react";
import { useEffect } from "react";
import AdminLayout from "./AdminLayout";
import { adminProduct } from "../redux/Slice/Admin/ProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../redux/Slice/Admin/CategorySlice";
import { getType } from "../redux/Slice/Admin/ProductTypeSlice";
import { useNavigate } from 'react-router-dom';
import { getColor } from "../redux/Slice/Admin/AdminColorSlice";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the styles

const AdminAddproduct = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [editorHtml, setEditorHtml] = useState('');
    const { categoryData, categoryStatus } = useSelector((state) => state.category);
    const { typeStatus, productType } = useSelector((state) => state.type);
    const { colorData, colorStatus } = useSelector((state) => state.color);
    const [selectedImages, setSelectedImages] = useState([]);
    const [color, setColor] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        price: '',
        discount: '',
        image: '',
        image1: '',
        image2: '',
        image3: '',
        type: '',
        category: '',
        qty: '',
        colorName: '',
        description: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        // Append selected images to formData
        const newImages = selectedImages;
        const mergedData = {
            ...formData,
            ...newImages
        };
        dispatch(adminProduct(formData));
        navigate("/admin/product");
    };

    useEffect(() => {
        dispatch(getCategory());
        dispatch(getType());
        dispatch(getColor());
    }, []);

    const productColor = () => {
        setColor(!color);
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image: file, // Assuming 'image' is the name of the field in formData
        });
    };
    // 1
    const handleImageChange1 = (e) => {
        const file1 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image1: file1, // Assuming 'image' is the name of the field in formData
        });
    };
    // 2
    const handleImageChange2 = (e) => {
        const file2 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image2: file2, // Assuming 'image' is the name of the field in formData
        });
    };
    // 3
    const handleImageChange3 = (e) => {
        const file3 = e.target.files[0];

        // Ensure formData is updated correctly with the image file
        setFormData({
            ...formData,
            image3: file3, // Assuming 'image' is the name of the field in formData
        });
    };

    const handleChange1 = (content) => {
        setEditorHtml(content);
    };
    AdminAddproduct.modules = {
        toolbar: [
          [{ 'header': '1'}, { 'header': '2' }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['bold', 'italic', 'underline'],
          [{ 'align': [] }],
          ['link', 'image']
        ],
      };
      AdminAddproduct.formats = [
        'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image'
      ];
      const editorStyle = {
        height: '400px', // Set height
        width: '100%'   // Set width, or specify a fixed width like '600px'
      };
    return (
        <AdminLayout>
            <form>



                <div className="space-y-12 w-8/12 m-auto">
                    <div className="pb-12">
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"

                                >
                                    Product Type
                                </label>
                                <div className="mt-2">
                                    <select name="type" value={formData.type} onChange={handleChange} className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {productType.map(option => (
                                            <option key={option.id} value={option.id}>{option.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"

                                >
                                    Category
                                </label>
                                <div className="mt-2">
                                    <select name="category" value={formData.category} onChange={handleChange} className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        {categoryData.map(data => (
                                            <option key={data.id} value={data.id}>{data.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="name"
                                    className="block text-sm font-medium leading-6 text-gray-900"

                                >
                                    name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="last_name"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Price
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        name="price"
                                        id="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                        autoComplete="last_name"
                                        className=" block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Discount
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="discount"
                                        name="discount"
                                        type="number"
                                        value={formData.discount}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Quantity
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="qty"
                                        type="number"
                                        value={formData.qty}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Color
                                </label>
                                <div className="mt-2">
                                    <input
                                        name="qty"
                                        type="checkbox"
                                        value={color}
                                        onChange={productColor}
                                    // className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            {color ? (
                                < div className="sm:col-span-3">
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium leading-6 text-gray-900"
                                    >
                                        Color Name
                                    </label>
                                    <div className="mt-2">
                                        {/* <input
                                            name="colorName"
                                            type="text"
                                            value={formData.colorName}
                                            onChange={handleChange}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        /> */}

                                        <select name="colorName" value={formData.colorName} onChange={handleChange} className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                            <option value=''>Select Color</option>
                                            {colorData.map(option => (
                                                <option key={option.id} value={option.name}>{option.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            ) : ("")
                            }
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="description"
                                    className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                    Description
                                </label>
                                <div className="mt-2">
                                    <textarea

                                        name="description"
                                        type="number"
                                        value={formData.description}
                                        onChange={handleChange}
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-6">
                                <ReactQuill
                                    value={editorHtml}
                                    style={editorStyle}
                                    onChange={handleChange1}
                                    modules={AdminAddproduct.modules}
                                    formats={AdminAddproduct.formats}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Image
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        onChange={handleImageChange}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            {/* 1 */}

                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Image 1
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        onChange={handleImageChange1}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            {/* 2 */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Image2
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        onChange={handleImageChange2}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                            {/* 3 */}
                            <div className="sm:col-span-3">
                                <label
                                    htmlFor="empcode"
                                    className="block text-sm font-medium leading-6 text-gray-900">
                                    Image3
                                </label>

                                <div className="mt-2">
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        onChange={handleImageChange3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-center gap-x-6">
                    <button type="button"
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" onClick={handleSubmit}>
                        Save
                    </button>
                    <a href='/admin/product'
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        Back
                    </a>
                </div>
            </form>
        </AdminLayout >
    )
}
export default AdminAddproduct;
