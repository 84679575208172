import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
} from "react-router-dom";
import Footer from "./components/home/Footer/Footer";
import FooterBottom from "./components/home/Footer/FooterBottom";
import Header from "./components/home/Header/Header";
import HeaderBottom from "./components/home/Header/HeaderBottom";
import SpecialCase from "./components/SpecialCase/SpecialCase";
import About from "./pages/About/About";
import SignIn from "./pages/Account/SignIn";
import SignUp from "./pages/Account/SignUp";
import Cart from "./pages/Cart/Cart";
import TrackOrder from "./pages/Cart/TrackOrder";
import UserProfile from "./pages/Account/UserProfile";
import Contact from "./pages/Contact/Contact";
import Home from "./pages/Home/Home";
import Journal from "./pages/Journal/Journal";
import Offer from "./pages/Offer/Offer";
import Payment from "./pages/payment/Payment";
import ProductDetails from "./pages/ProductDetails/ProductDetails";
import Shop from "./pages/Shop/Shop";
import AdminLayout from "./Admin/AdminLayout";
import AdminProduct from "./Admin/AdminProduct";
import AdminAddproduct from "./Admin/AdminAddproduct";
import AdminLogin from "./Admin/AdminLogin";
import ProductType from "./Admin/ProductType";
import ProductTypeAdd from "./Admin/ProductTypeAdd";
import AdminCategory from "./Admin/AdminCategory";
import AdminAddCategory from "./Admin/AdminAddCategory";
import AdminOffers from "./Admin/AdminOffers";
import AdminAddOffers from "./Admin/AdminAddOffers";
import EditAdminProduct from "./Admin/EditAdminProduct";
import EditAdminCategory from "./Admin/EditAdminCategory";
import AdminOrderHistory from "./Admin/AdminOrderHistory";
import AdminColor from "./Admin/AdminColor";
import AdminAddColor from "./Admin/AdminAddColor";
import AdminRoute from "./Route/AdminRoute";
import SellerRoute from "./Route/SellerRoute";
import AddressConformed from "./components/Orders/AddressConformed";
import AdminLanding from "./Admin/AdminLanding";
import Banner3image from "./Admin/Banner3image";
import Banner3imageList from "./Admin/Banner3imageList";
import Compare from "./pages/Compare";
import CompareList from "./pages/CompareList";
import OrderHistory from "./components/Orders/OrderHistory";
import AdminOrderStatus from "./Admin/AdminOrderStatus";
import AdminEditOrderStatus from "./Admin/AdminEditOrderStatus";
import Dashboard from "./Admin/Dashboard";
const Layout = () => {
  return (
    <div>
      <Header />
      <HeaderBottom />
      <SpecialCase />
      <ScrollRestoration />
      <Outlet />
      <Footer />
      <FooterBottom />
    </div>
  );
};
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/admin-login" element={<AdminLogin />} />
      <Route path="/admin/product" element={<AdminProduct />} />
      <Route path="/admin/add/product" element={<AdminAddproduct />} />
      <Route path="/admin/edit/product" element={<EditAdminProduct />} />

      <Route path="/admin/product/type" element={<ProductType />} />
      <Route path="/admin/product/type/add" element={<ProductTypeAdd />} />

      <Route path="/admin/product/category" element={<AdminCategory />} />
      <Route path="/admin/add/category" element={<AdminAddCategory />} />
      <Route path="/admin/edit/category" element={<EditAdminCategory />} />
      <Route path="/admin/product/offer" element={<AdminOffers />} />
      <Route path="/admin/landing" element={<AdminLanding />} />
      <Route path="/admin/product/add/offer" element={<AdminAddOffers />} />
      <Route path="/admin/order/history" element={<AdminOrderHistory />} />
      <Route path="/admin/order/status" element={<AdminOrderStatus />} />
      <Route path="/admin/order/status/edit" element={<AdminEditOrderStatus />} />
      <Route path="/admin/color" element={<AdminColor />} />
      <Route path="/admin/color/add" element={<AdminAddColor />} />
      <Route path="/admin/banner3image" element={<Banner3image />} />
      <Route path="/admin/Banner3imageList" element={<Banner3imageList />} />
      <Route path="/admin/dashboard" element={<Dashboard />}></Route>

      <Route path="/" element={<Layout />}>
        {/* ==========11========== Header Navlink Start here =================== */}
        <Route index element={<Home />}></Route>
        <Route path="/shop" element={<Shop />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/journal" element={<Journal />}></Route>
        {/* ==================== Header Navlink End here ===================== */}
        <Route path="/offer" element={<Offer />}></Route>
        <Route path="/product/:_id" element={<ProductDetails />}></Route>
        <Route path="/cart" element={<Cart />}></Route>
        <Route path="/track-order" element={<TrackOrder />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route path="/address-confirmed" element={<AddressConformed />}></Route>

        <Route path="/paymentgateway" element={<Payment />}></Route>
        <Route path="compare" element={<Compare />} />
        <Route path="compare/list" element={<CompareList />} />
        <Route path="orderhistory" element={<OrderHistory />} />
      </Route>
      <Route path="/signup" element={<SignUp />}></Route>
      <Route path="/signin" element={<SignIn />}></Route>
      
    </Route>
  )
);

function App() {
  return (
    <div className="font-bodyFont">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
