import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { useNavigate } from 'react-router-dom';
const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [compareSession, setCompareSession] = useState([]);
  const productcompare = (id) => {
    localStorage.setItem("compare_id", productInfo._id);
    setCompareSession(productInfo._id);
    navigate('/compare');
  }
console.log(productInfo);
  return (
    <div className="flex flex-col gap-5">
      <h2 className="text-4xl font-semibold">{productInfo.productName || productInfo.name}</h2>

      {
        productInfo.discount && productInfo.discount > 0 ? (
          <p className="text-xl font-semibold">
            ${productInfo.discount}
            <span className="line-through text-gray-500 mx-2 text-lg font-normal">${productInfo.price}</span>
          </p>
        ) : (
          <p className="text-xl font-semibold">${productInfo.price}</p>
        )
      }
      <p className="text-sm">{productInfo.description}</p>
      {productInfo.qty} items available in stock
      <p className="font-medium text-lg">
        <span className="font-normal">Colors:</span> {productInfo.color}
      </p>
      <div className="flex space-x-4">
        <button
          disabled={productInfo.qty <= 0}
          onClick={() =>
            dispatch(
              addToCart({
                _id: productInfo._id,
                name: productInfo.productName || productInfo.name,
                quantity: 1,
                image: productInfo.img,
                badge: productInfo.badge,
                price: productInfo.price,
                discount: productInfo.discount,
                colors: productInfo.color,
              })
            )
          }
          className="w-1/6 py-4 bg-white rounded-lg duration-300 text-black text-sm font-titleFont disabled:opacity-50 disabled:cursor-not-allowed border border-red hover:text-red"


        >

          {productInfo.qty <= 0 ? "Out of Stock" : "Add to Cart"}
        </button>
        {/* <button className="w-1/6 py-4 bg-white rounded-lg duration-300 text-black text-sm font-titleFont disabled:opacity-50 disabled:cursor-not-allowed border border-red hover:text-red">
          {productInfo.qty <= 0 ? "Out of Stock" : "Whishlist"}
        </button> */}
        <button
          className="w-1/6 py-4 bg-white rounded-lg duration-300 text-black text-sm font-titleFont disabled:opacity-50 disabled:cursor-not-allowed border border-red hover:text-red"
          onClick={(e) => productcompare(productInfo.id)}
          disabled={productInfo.qty <= 0}

        // Disable button if product is out of stock
        >
          {productInfo.qty <= 0 ? "Out of Stock" : "Compare"}
        </button>

        <button className="w-1/6 py-4 bg-white rounded-lg duration-300 text-black text-sm font-titleFont disabled:opacity-50 disabled:cursor-not-allowed border border-red hover:text-red">
          {productInfo.qty <= 0 ? "Out of Stock" : "Buy Now"}
        </button>
      </div>
      <p className="font-normal text-sm">
        <span className="text-base font-medium"> Categories:</span> Spring
        collection, Streetwear, Women Tags: featured SKU: N/A
      </p>

      <p className="text-base text-gray-600">{productInfo.des}</p>
    </div>
  );
};

export default ProductInfo;
