import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { productOfTheYear } from "../../../assets/images";
import ShopNow from "../../designLayouts/buttons/ShopNow";
import Image from "../../designLayouts/Image";
import { useDispatch, useSelector } from "react-redux";
import { ProductYear } from "../../../redux/Slice/Seller/ProductofYearSlice";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import { useNavigate } from "react-router-dom";
const YearProduct = () => {
  const dispatch = useDispatch();
  const { yearData } = useSelector((state) => state.year);
  const _id = yearData && yearData.name;

  const productItem = yearData;
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(ProductYear());
  }, []);

  if (!yearData) return <div>Loading...</div>;
  const idString = (_id) => {
    return String(_id).toLowerCase().split(" ").join(" ");
  };
  const rootId = idString(_id);
  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: {
        item: productItem,
      },
    });
  };

  return (
    // <Link to="/shop">
    <div
      onClick={handleProductDetails}
      className="w-full h-80 mb-20 bg-black md:bg-transparent relative font-titleFont flex-row justify-center items-center ">
      <img
        className="h-[350px] w-[350px] pl-16  p-8 overflow-hidden  md:inline-block"
        src={yearData.image}
      />
      <div className="w-full md:w-2/3 xl:w-1/2 h-80 absolute px-4 md:px-0 top-0 right-0 flex flex-col items-start gap-6 justify-center">
        <h1 className="text-3xl font-semibold text-primeColor">
          Product of The year
        </h1>
        <p className="text-base font-normal text-primeColor max-w-[600px] mr-4">
          {yearData.description}
        </p>
        <ShopNow />
      </div>
      <span className="inline-block py-1 float-right px-3 mr-6 text-sm font-medium text-white bg-gradient-to-r from-green-400 to-green-600 rounded-full shadow-lg hover:shadow-xl transition duration-200">
        Upto ₹{yearData.discount} OFF
      </span>
    </div>
    // </Link>
  );
};

export default YearProduct;
