import React from "react";
import { getProduct } from "../redux/Slice/Admin/ProductSlice";
import AdminLayout from "./AdminLayout";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import EditAdminProduct from "./EditAdminProduct";
import { useNavigate } from "react-router-dom";
const  AdminProduct = () => {
    const navigate = useNavigate();
    const { productData, productStatus } = useSelector((state) => state.product);
    const dispatch = useDispatch();
    React.useEffect(() => {
        dispatch(getProduct());
    }, [dispatch, productStatus, productData.length]);
    const editProject = (data, e) => {
        e.preventDefault();
        const queryParams = new URLSearchParams(data).toString();
        navigate(`/admin/edit/product?${queryParams}`);
    }
    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-header">
                    <Link
                        to="/admin/add/product"
                        className="card rounded-md bg-blue-600 px-3 p-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 float-end mb-2"
                    >
                        Add Product
                    </Link>
                </div>
                <div className="card-body">
                    <table className=" productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">Id</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Name</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Price</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Discount</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Category</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Type</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Quantity</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Image</th>
                                <th className="w-40 bg-blue-950 p-2 text-white">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productStatus === 'success' ? (
                                productData && productData.length > 0 && productData.map(product => (
                                    <tr key={product.id}>
                                        <td className="text-center">{product.id}</td>
                                        <td className="text-center">{product.name}</td>
                                        <td className="text-center">{product.price}</td>
                                        <td className="text-center">{product.discount}</td>
                                        <td className="text-center">{product.category ? product.category : 'N/A'}</td>
                                        <td className="text-center">{product.type ? product.type : 'N/A'}</td>
                                        <td className="text-center">{product.quantity ? product.quantity : '0'}</td>
                                        <td className="text-center">
                                            <img src={product.images} width="100" alt={product.name} />
                                        </td>
                                        <td className="text-center cursor-pointer" onClick={(e) => editProject(product, e)}>Edit</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="text-center">Wait</td>
                                </tr>
                            )}


                        </tbody>


                    </table>
                </div>
            </div>
        </AdminLayout>
    );
};
export default AdminProduct;
