import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import Heading from "../Products/Heading";
import SampleNextArrow from "./SampleNextArrow";
import SamplePrevArrow from "./SamplePrevArrow";
import { useDispatch, useSelector } from "react-redux";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import Product from "../Products/Product";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const NewArrivals = () => {
    const dispatch = useDispatch();
    const { status, data } = useSelector((state) => state.ProductList);

    useEffect(() => {
        dispatch(allproduct());
    }, []);

    // Filter by type == 1 and take the first 5 items
    const sortedData = [...data].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    const latestProducts = sortedData.filter((product) => product);
    const [dotActive, setDocActive] = useState(0);
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        arrows: true,

        beforeChange: (prev, next) => {
            const prevArrow = document.querySelector(".slick-prev");
            const nextArrow = document.querySelector(".slick-next");

            if (prevArrow && nextArrow) {
                prevArrow.classList.add("slick-arrow-prev");
                nextArrow.classList.add("slick-arrow-next");
                prevArrow.style.position = "absolute";
                prevArrow.style.top = "50%";
                prevArrow.style.left = "0";
                prevArrow.style.transform = "translateY(-50%)";

                nextArrow.style.position = "absolute";
                nextArrow.style.top = "50%";
                nextArrow.style.right = "0";
                nextArrow.style.transform = "translateY(-50%)";
            }
        },
        appendDots: (dots) => (
            <div
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "7%",
                    transform: "translateY(-50%)",
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
        customPaging: (i) => (
            <div
                style={
                    i === dotActive
                        ? {
                            width: "30px",
                            color: "#262626",
                            borderRight: "3px #262626 solid",
                            padding: "8px 0",
                            cursor: "pointer",
                        }
                        : {
                            width: "30px",
                            color: "transparent",
                            borderRight: "3px white solid",
                            padding: "8px 0",
                            cursor: "pointer",
                        }
                }
            >
                0{i + 1}
            </div>
        ),
        responsive: [
            {
                breakpoint: 576,
                settings: {
                    customPaging: (i) => (
                        <div
                            style={
                                i === dotActive
                                    ? {
                                        width: "25px",
                                        color: "#262626",
                                        borderRight: "3px #262626 solid",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                    }
                                    : {
                                        width: "25px",
                                        color: "transparent",
                                        borderRight: "3px white solid",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                    }
                            }
                        >
                            0{i + 1}
                        </div>
                    ),
                },
            },
        ],
    };

    return (
        <div className="w-full pb-20   ">
            <Heading heading="New Arrivals" />
            <Slider {...settings}>
                {latestProducts.map((product) => (
                    <div className="px-2" key={product.id}>




                        <Product
                            _id={product.id}
                            img={product.images}
                            img1={product.images1}
                            img2={product.images2}
                            img3={product.images3}
                            productName={product.name}
                            price={product.price}
                            color={product.color}
                            badge={false}
                            des={product.description}
                        />
                    </div>
                ))}
            </Slider>
        </div>

    );
};

export default NewArrivals;