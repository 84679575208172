import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = async() => {
  return localStorage.getItem("token");
};

export const fetchBanner = createAsyncThunk(
  "Employee/fetchProfile",
  async () => {
    const token = await getTokenFromLocalStorage();
    const res = await fetch(`${apiUrl}product/offers`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });

    const final = await res.json();
    return final.data;
  }
);

const BannerSlice = createSlice({
  name: "banner",
  initialState: {
    bannerData: null,
    isLoader: false,
    isError: false,
    bannerstatus: "idel",
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBanner.pending, (state) => {
      state.isLoader = true;
      state.bannerstatus = "pending";
    });
    builder.addCase(fetchBanner.fulfilled, (state, action) => {
      state.isLoader = false;
      state.bannerstatus = "success";
      state.bannerData = action.payload;
    });
    builder.addCase(fetchBanner.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
      state.bannerData = null;
      state.bannerstatus = "rejected";
    });
  },
});

export default BannerSlice.reducer;
