import React from "react";
import { Link } from "react-router-dom";
import AdminLayout from "./AdminLayout";

const AdminLanding = () => {
    return (
        <AdminLayout>
            <div className="card mt-3">
                <div className="card-header">
                    <Link
                        to="/admin/landing/add"
                        className="card rounded-md bg-blue-600 px-3 p-5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 float-end mb-2"
                    >
                        Add Data
                    </Link>
                </div>
                <div className="card-body">
                    <table className=" productTable w-full border border-collapse mt-0">
                        <thead>
                            <tr>
                                <th className="w-40 bg-blue-950 p-2 text-white">
                                    Id
                                </th>
                                <th className="w-40 bg-blue-950 p-2 text-white">
                                    Name
                                </th>
                                <th className="w-40 bg-blue-950 p-2 text-white">
                                    Image
                                </th>
                                <th className="w-40 bg-blue-950 p-2 text-white">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {offerData.map((product) => (
                                <tr key={product.id}>
                                    <td className="text-center">
                                        {product.id}
                                    </td>
                                    <td className="text-center">
                                        {product.name}
                                    </td>
                                    <td className="text-center flex justify-center">
                                        <img
                                            src={product.image}
                                            width="100"
                                            alt={product.name}
                                        />
                                    </td>
                                    <td className="text-center">Edit</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </AdminLayout>
    );
};

export default AdminLanding;

const offerData = [
    {
        id: 1,
        name: "Offer 1",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 2,
        name: "Offer 2",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 3,
        name: "Offer 3",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 4,
        name: "Offer 4",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 5,
        name: "Offer 5",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 6,
        name: "Offer 6",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 7,
        name: "Offer 7",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 8,
        name: "Offer 8",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 9,
        name: "Offer 9",
        image: "https://via.placeholder.com/150",
    },
    {
        id: 10,
        name: "Offer 10",
        image: "https://via.placeholder.com/150",
    },
];
