import React from "react";
import { useState } from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";




const Product = (props) => {
    const dispatch = useDispatch();
    const _id = props.productName;
    const [compareSession, setCompareSession] = useState([]);

    const idString = (_id) => {
        return String(_id).toLowerCase().split(" ").join("");
    };
    const rootId = idString(_id);

    const navigate = useNavigate();
    const productItem = props;
    const handleProductDetails = () => {
        navigate(`/product/${rootId}`, {
            state: {
                item: productItem,
            },
        });
    };
    const productcompare = (id) => {
        localStorage.setItem("compare_id", props.id);
        setCompareSession(props.id);
        navigate('/compare');
    }
    return (
        <>
            {props.searchField !== undefined && props.searchField ? (
                <div className="w-full relative group mx-auto bg-white   left-0 z-50  shadow-2xl scrollbar-hide cursor-pointer" onClick={handleProductDetails}>
                    <div
                        onClick={handleProductDetails}
                        key={props.key}
                        className="max-w-[600px] h-28 bg-gray-100 mb-3 flex items-center gap-3"
                    >
                        <Image className="w-[8vw] h-[16vh]" imgSrc={props.img} />

                        <div className="flex flex-col gap-1">
                            <p className="font-semibold text-lg">
                                {props.productName}
                            </p>
                            <p className="text-xs">{props.des}</p>
                            <p className="text-sm">
                                Price:{" "}
                                <span className="text-primeColor font-semibold">
                                    {
                                        props.discount && props.discount > 0 ? (
                                            <span>
                                                ${props.discount}
                                                <span className="line-through text-gray-500 mx-2 font-normal">${props.price}</span>
                                            </span>
                                        ) : (
                                            <span>${props.price}</span>
                                        )
                                    }
                                </span>
                            </p>
                        </div>
                    </div>

                </div>
            ) : (
                <div onClick={handleProductDetails}
                    className=" group flex flex-col w-[16vw] h-[50] relative group border border-gray-200 shadow-md rounded-lg hover:shadow-lg  hover:scale-105 transition duration-300">
                    <div className="product-image flex justify-center items-center  w-full h-40 relative overflow-y-hidden ">
                        {props.qty <= 0 && (
                            <div className="absolute top-0 right-0 bg-red-500 text-white m-3 p-2 text-xs">Out of stock</div>
                        )}
                        <Image className=" max-w-36 h-48 object-contain p-3 m-2 group-hover:scale-105 transition duration-300" imgSrc={props.img} />
                        {props.badge && <Badge text="New" className="absolute top-6 left-8" />}
                    </div>
                    <div className="product-info flex flex-col p-4">
                        <h2 className=" text-primeColor truncate">{props.productName.split(" ").slice(0, 3).join(" ")}</h2>
                        <p className="text-[#767676] font-bold text-[14px]">{props.color}</p>
                        <div className="flex items-center justify-between font-titleFont">
                            <p className="text-[#767676] text-[14px] self-end text-end">
                                {props.discount && props.discount > 0 ? (
                                    <>
                                        <span className="text-[#767676] text-[14px]"> ${props.price - props.discount} </span>
                                        <span className="line-through text-red-500 text-[14px] ml-1"> ${props.price} </span>
                                    </>
                                ) : (
                                    <span className="text-[#767676] text-[14px]"> ${props.price} </span>
                                )}
                            </p>
                        </div>
                        <div className="absolute left-0 w-full h-auto bg-white border-t border-gray-200 -bottom-[130px] opacity-0 pointer-events-none duration-700 group-hover:pointer-events-auto group-hover:opacity-100 group-hover:bottom-0">
                            <ul className="flex flex-col justify-center gap-1 font-titleFont border-l border-r">
                                <li
                                    onClick={(e) => productcompare(props.id)}
                                    className="text-[#767676] hover:text-primeColor text-sm font-normal flex items-center justify-center gap-2 hover:cursor-pointer duration-300"
                                >
                                    Compare <span> <GiReturnArrow /> </span>
                                </li>
                                {props.qty > 0 ? (
                                    <li
                                        onClick={() =>
                                            dispatch(
                                                addToCart({
                                                    _id: props._id,
                                                    name: props.productName,
                                                    quantity: 1,
                                                    image: props.img,
                                                    badge: props.badge,
                                                    price: props.price,
                                                    colors: props.color,
                                                })
                                            )
                                        }
                                        className="text-[#767676] hover:text-primeColor text-sm font-normal flex items-center justify-center gap-2 hover:cursor-pointer duration-300"
                                    >
                                        Add to Cart <span> <FaShoppingCart /> </span>
                                    </li>
                                ) : null}

                            </ul>
                        </div>
                    </div>

                </div>

            )}
        </>
    );
};

export default Product;
