import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
export const signUp = createAsyncThunk(
  "signUp",
  async (credentials) => {
    // const token = "23|3q3e9wLHiwjFmEQWxY8DX6mn8IHf4o9tDy8juyMQf54cc898";

    const res = await fetch(`${apiUrl}user/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);

    }
    return final.data;
  }
);
// 2
export const signIn = createAsyncThunk(
  "signIn",
  async (credentials) => {
    // const token = "23|3q3e9wLHiwjFmEQWxY8DX6mn8IHf4o9tDy8juyMQf54cc898";

    const res = await fetch(`${apiUrl}user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    localStorage.setItem("token", final.data.token);
    if (final.data.status) {
      alert(final.data.message);
    }
    return final.data;
  }
);


const AuthSlice = createSlice({
  name: "signup",
  initialState: {
    authData: [],
    isLoader: false,
    isError: false,
    authStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(signUp.fulfilled, (state, action) => {
      state.authStatus = "success";
      state.isLoader = false;
      state.authData = action.payload;
    });
    builder.addCase(signUp.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
    //  2
    builder.addCase(signIn.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(signIn.fulfilled, (state, action) => {
      state.authStatus = "success";
      state.isLoader = false;
      state.authData = action.payload;
    });
    builder.addCase(signIn.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default AuthSlice.reducer;
