import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";
const getTokenFromLocalStorage = async() => {
    return localStorage.getItem("token");
};
export const addBanner = createAsyncThunk(
    "admin/addbanner",
    async (data) => {
        const token = await getTokenFromLocalStorage();
        try {
            const formData = new FormData();
            formData.append('image', data.image);
            formData.append('name', data.name);

            const res = await fetch(`${apiUrl}admin/banner/add`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData, // Pass formData directly as the body
            });

            const final = await res.json();

            if (final.status) {
                handleApiSuccess(final.message);
            } else {
                handleApiError(final.errors);
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);
// 2
export const getBanner = createAsyncThunk(
    "admin/getBanner",
    async (data) => {
        const token = await getTokenFromLocalStorage();
        try {
            
            const res = await fetch(`${apiUrl}admin/banner`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                // body: formData, // Pass formData directly as the body
            });

            const final = await res.json();

            if (final.status) {
                // handleApiSuccess(final.message);
            } else {
                handleApiError(final.errors);
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);
//3
export const updateBanner = createAsyncThunk(
    "admin/updateBanner",
    async (data) => {
        const token = await getTokenFromLocalStorage();
        try {
            const formData = new FormData();
            formData.append('id', data.id);
            formData.append('image', data.image);
            formData.append('name', data.name);

            const res = await fetch(`${apiUrl}admin/banner/update`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData, // Pass formData directly as the body
            });

            const final = await res.json();

            if (final.status) {
                handleApiSuccess(final.message);
            } else {
                handleApiError(final.errors);
            }

            return final.data;
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);
const AdminBannerSlice = createSlice({
    name: "color",
    initialState: {
        bannerdata: [],
        isLoader: false,
        isError: false,
        bannerStatus: 'pending',
    },
    extraReducers: (builder) => {
        builder
            .addCase(addBanner.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(addBanner.fulfilled, (state, action) => {
                state.bannerStatus = 'success';
                state.isLoader = false;
                state.bannerdata = action.payload;
            })
            .addCase(addBanner.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
            // 2
            builder
            .addCase(getBanner.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(getBanner.fulfilled, (state, action) => {
                state.bannerStatus = 'success';
                state.isLoader = false;
                state.bannerdata = action.payload;
            })
            .addCase(getBanner.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
            //3
            builder
            .addCase(updateBanner.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(updateBanner.fulfilled, (state, action) => {
                state.bannerStatus = 'success';
                state.isLoader = false;
                state.bannerdata = action.payload;
            })
            .addCase(updateBanner.rejected, (state, action) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});
export default AdminBannerSlice.reducer;