import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
const getTokenFromLocalStorage = async () => {
  return localStorage.getItem("token");
};
export const CompareProductList = createAsyncThunk(
  "comapre",
  async (credentials) => {
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}product/compare`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final) {
      // alert(final.message);
    }
    return final.data;
  }
);
// 2
const CompareSlice = createSlice({
  name: "compare",
  initialState: {
    compareData: [],
    isLoader: false,
    isError: false,
    compareStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(CompareProductList.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(CompareProductList.fulfilled, (state, action) => {
      state.compareStatus = "success";
      state.isLoader = false;
      state.compareData = action.payload;
    });
    builder.addCase(CompareProductList.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});
export default CompareSlice.reducer;
