import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret, faAddressCard, faUser, faEnvelope, faPhone, faMobile, faMobileAlt, faEdit, faMapPin } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';


const UserProfile = () => {
    const { profileData, profileStatus } = useSelector((state) => state.profile);
    console.log("akpka", JSON.stringify(profileData))
    return (
        <div className="flex " >
            <div className="sidebar w-64 p-4 bg-gray-100">

                <div className="account-heading mt-[20px] font-bold text-lg text-gray-800">Account  </div><FontAwesomeIcon icon={faCircle} className="text-green-500 float-right mt-[-20px] h-3 w-3" />
                <div className="text-gray-600 text-sm mb-6 border-b-4   rounded ">Orebi User</div>


                <div className="segment-segment  mb-3 border-b-2">
                    <div className="segment-heading font-bold text-lg text-gray-800">ORDERS</div>
                    <a href="/my/orders" className=" text-gray-600 hover:text-blue-500">Orders & Returns</a>
                </div>
                <div className="segment-segment mb-3 border-b-2">
                    <div className="segment-heading font-bold text-lg text-gray-800">CREDITS</div>
                    <a href="/my/coupons" className="segment-link text-gray-600 hover:text-blue-500">Coupons</a>
                </div>
                <div className="flex-col segment-segment mb-3 border-b-2">
                    <div className="segment-heading font-bold text-lg text-gray-800">ACCOUNT</div>

                    <a href="/my/address" className="segment-link text-gray-600 hover:text-blue-500">Addresses</a><br />
                    <a href="/my/user/delete" className="segment-link text-gray-600 hover:text-blue-500">Delete Account</a>
                </div>
                <div className="segment-segment mb-3 border-b-2">
                    <div className="segment-heading font-bold text-lg text-gray-800">LEGAL</div>
                    <a href="/termsofuse" className="segment-link text-gray-600 hover:text-blue-500">Terms of Use</a><br />
                    <a href="/privacypolicy" className="segment-link text-gray-600 hover:text-blue-500">Privacy Policy</a>
                </div>
            </div>
            <div className="container mx-auto p-4 bg-white rounded-lg">
                <div className="flex flex-col items-center justify-center mb-8">
                    <FontAwesomeIcon icon={faUserSecret} size="4x" className="border-2 border-blue-300 p-4 rounded-full text-gray-600" />
                    <h2 className="text-2xl font-bold text-gray-800">{profileData.name}</h2>
                </div>
                <div className="flex flex-wrap justify-center mb-8">
                    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4">
                        <h3 className="text-lg p-3 text-gray-600 font-semibold">
                            <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                            Personal Information:
                        </h3>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faUser} className="mr-2" />
                                    Full Name:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.name}</span>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                                    Email ID:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.email}</span>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faAddressCard} className="mr-2" />
                                    Address:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.address || "not added"}</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-1/2 xl:w-1/2 p-4">
                        <h3 className="text-lg p-3 text-gray-600 font-semibold">
                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                            Contact Information:
                        </h3>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faMobile} className="mr-2" />
                                    Mobile Number:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.phone}</span>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faMobileAlt} className="mr-2" />
                                    Alternate Mobile:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.alternatePhone || "not added"}</span>
                            </div>
                        </div>
                        <div className="bg-gray-100 p-4 rounded mb-4">
                            <div className="flex justify-between">
                                <span className="text-sm text-gray-600">
                                    <FontAwesomeIcon icon={faMapPin} className="mr-2" />
                                    Pin Code:
                                </span>
                                <span className="text-sm text-gray-800">{profileData.zip || "not added"}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <button className="px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white rounded">
                        <FontAwesomeIcon icon={faEdit} className="mr-2" />
                        EDIT
                    </button>
                </div>
            </div>


        </div>

    );
};

export default UserProfile;