import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";

const getTokenFromLocalStorage = async () => {
  return localStorage.getItem("token");
};

export const addorder = createAsyncThunk(
  "order/add", // Unique action type for addorder
  async (credentials) => {
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}orders/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final) {
      alert(final.message);
    }
    return final.data;
  }
);

export const order = createAsyncThunk(
  "order/signIn", // Unique action type for order
  async (credentials) => {
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}user/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);
    }
    return final.data;
  }
);

export const orderhistory = createAsyncThunk(
  "order/history", // Unique action type for orderhistory
  async (credentials) => {
    const token = await getTokenFromLocalStorage();

    const res = await fetch(`https://ruminate.testingruminate.co.in/public/api/orders/history`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    });
    const final = await res.json();
    if (final.data.status) {
      alert(final.data.message);
    }
    return final.data;
  }
);

const OrderSlice = createSlice({
  name: "order",
  initialState: {
    orderData: [],
    isLoader: false,
    isError: false,
    orderStatus: "pending",
  },
  extraReducers: (builder) => {
    builder.addCase(addorder.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(addorder.fulfilled, (state, action) => {
      state.orderStatus = "success";
      state.isLoader = false;
      state.orderData = action.payload;
    });
    builder.addCase(addorder.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    builder.addCase(order.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(order.fulfilled, (state, action) => {
      state.orderStatus = "success";
      state.isLoader = false;
      state.orderData = action.payload;
    });
    builder.addCase(order.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });

    builder.addCase(orderhistory.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.addCase(orderhistory.fulfilled, (state, action) => {
      state.orderStatus = "success";
      state.isLoader = false;
      state.orderData = action.payload;
    });
    builder.addCase(orderhistory.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default OrderSlice.reducer;
