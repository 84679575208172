import React from "react";
import { Link } from "react-router-dom";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBanner } from "../../../redux/Slice/Admin/AdminBannerSlice";
import {
  saleImgOne,
  saleImgTwo,
  saleImgThree,
} from "../../../assets/images/index";
import Image from "../../designLayouts/Image";

const Sale = () => {
  const dispatch = useDispatch();
  // const { status, data } = useSelector((state) => state.ProductList);
  const { bannerdata, bannerStatus } = useSelector(
    (state) => state.adminbanner
  );
  useEffect(() => {
    dispatch(getBanner());
  }, []);
  return (
    // <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
    //   <div className="w-full md:w-2/3 lg:w-1/2 h-full">
    //     <Link to="/shop">
    //       <Image className="h-full w-full object-cover" imgSrc={saleImgOne} />
    //     </Link>
    //   </div>
    //   <div className="w-full md:w-2/3 lg:w-1/2 h-auto flex flex-col gap-4 lg:gap-10">
    //     <div className="h-1/2 w-full">
    //       <Link to="/shop">
    //         <Image className="h-full w-full object-cover" imgSrc={saleImgTwo} />
    //       </Link>
    //     </div>
    //     <div className="h-1/2 w-full">
    //       <Link to="/shop">
    //         <Image
    //           className="h-full w-full object-cover"
    //           imgSrc={saleImgThree}
    //         />
    //       </Link>
    //     </div>
    //   </div>
    // </div>
    // 
    <>
      <div className="py-20 flex flex-col md:flex-row items-center justify-between gap-4 lg:gap-10">
        {bannerdata.map(banner => (
          <div key={banner.id} className="w-full overflow-hidden md:w-2/3 lg:w-1/2 h-full p-2">
            <Link to="/shop">
              <img className="h-[15em] w-full object-contain transition duration-300 ease-in-out hover:scale-105 hover:shadow-lg" src={banner.image} alt={banner.name} />
            </Link>
          </div>
        ))}
      </div>
    </>



  );
};

export default Sale;
