import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import { useDispatch, useSelector } from "react-redux";
import { allproduct } from "../../../redux/Slice/Seller/ProductListSlice";
import { useEffect } from "react";
import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
} from "../../../assets/images/index";
import { GiConsoleController } from "react-icons/gi";
const SpecialOffers = () => {
  const dispatch = useDispatch();
  const { status, data } = useSelector((state) => state.ProductList);
  useEffect(() => {
    dispatch(allproduct());

  }, []);




  return (

    <div className="w-full pb-20">
      <Heading heading="Special Offers" />
      <span className="  text-red-500 mt-[-9vh] pl-[19vw] p-2 animate-pulse flex border-b text-base font-thin ">
        Limited Time Discount
      </span>
      <div className=" w-full grid grid-cols-1 justify-items-center md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-5">

        {data.map((product) => (
          product.type == 3 ? (
            <div key={product.id}>
              <span className="inline-block py-1 px-3 text-sm font-medium text-white bg-gradient-to-r from-green-400 to-green-600 rounded-full shadow-lg hover:shadow-xl transition duration-200">
                {product.price !== 0 ? (product.discount / product.price * 100).toFixed(0) : 0}% OFF
              </span>
              <Product
                _id={product.id}
                img={product.images}
                img1={product.images1}
                img2={product.images2}
                img3={product.images3}
                productName={product.name}
                color={product.color}
                des={product.description}
                price={product.price}
                discount={product.discount}
              />
              {/* <div className="ml-[]">
                <span className="text-red-500 text-sm line-through">
                  ${product.price}
                </span>
                <span className="text-gray-500 text-lg">
                  ${product.price - (product.price * product.discount) / 100}
                </span>
              </div> */}



            </div>
          ) : null
        ))}



        {/* <Product
          _id="1101"
          img={spfOne}
          productName="Cap for Boys"
          price="35.00"
          color="Blank and White"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1102"
          img={spfTwo}
          productName="Tea Table"
          price="180.00"
          color="Gray"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1103"
          img={spfThree}
          productName="Headphones"
          price="25.00"
          color="Mixed"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
        {/* <Product
          _id="1104"
          img={spfFour}
          productName="Sun glasses"
          price="220.00"
          color="Black"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        /> */}
      </div>
    </div >
  );
};

export default SpecialOffers;
