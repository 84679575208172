import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../apiConfig";
import { handleApiError } from "../../../Helpers/handleApiError";
import { handleApiSuccess } from "../../../Helpers/handleApiSuccess";

// Function to retrieve token from local storage
const getTokenFromLocalStorage = async () => {
    return localStorage.getItem("token");
};

// Thunks
export const fetchOrderHistory = createAsyncThunk(
    "order/fetchHistory",
    async (data) => {
        const token = await getTokenFromLocalStorage();
        try {
            const queryString = new URLSearchParams(data).toString();
            const res = await fetch(`${apiUrl}admin/orders`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });
            const final = await res.json();
            if (final.status) {
                // handleApiSuccess(final.message); // Optional
            } else {
                handleApiError(final.errors);
            }
            return final.data; // Make sure the API returns the expected data here
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);

export const fetchOrderStatus = createAsyncThunk(
    "order/fetchStatus",
    async (data) => {
        const token = await getTokenFromLocalStorage();
        try {
            const queryString = new URLSearchParams(data).toString();
            const res = await fetch(`${apiUrl}admin/orders/status`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });
            const final = await res.json();
            if (final.status) {
                // handleApiSuccess(final.message); // Optional
            } else {
                handleApiError(final.errors);
            }
            return final.data; // Make sure the API returns the expected data here
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);

// 3
export const editOrderStatus = createAsyncThunk(
    "order/fetchStatus/update",
    async (data) => {
        console.log(data);
        const token = await getTokenFromLocalStorage();
        try {
            const res = await fetch(`${apiUrl}admin/orders/status/update`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(data),
            });
            const final = await res.json();
            if (final.status) {
                // handleApiSuccess(final.message); // Optional
            } else {
                handleApiError(final.errors);
            }
            return final.data; // Make sure the API returns the expected data here
        } catch (error) {
            handleApiError(error, "failed");
            throw error;
        }
    }
);
// Slice
const OrderHistorySlice = createSlice({
    name: "orderHistory",
    initialState: {
        orderHistory: [],
        isLoader: false,
        isError: false,
        historyStatus: 'pending',
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderHistory.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action) => {
                state.historyStatus = 'success';
                state.isLoader = false;
                state.orderHistory = action.payload;
            })
            .addCase(fetchOrderHistory.rejected, (state) => {
                state.isLoader = false;
                state.isError = true;
            })
            .addCase(fetchOrderStatus.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(fetchOrderStatus.fulfilled, (state, action) => {
                state.historyStatus = 'success';
                state.isLoader = false;
                state.orderHistory = action.payload;
                console.log("payload data", state.orderHistory); // Debugging
            })
            .addCase(fetchOrderStatus.rejected, (state) => {
                state.isLoader = false;
                state.isError = true;
            })

            // 3
            .addCase(editOrderStatus.pending, (state) => {
                state.isLoader = true;
                state.isError = false;
            })
            .addCase(editOrderStatus.fulfilled, (state, action) => {
                state.historyStatus = 'success';
                state.isLoader = false;
                state.orderHistory = action.payload;
                console.log("payload data", state.orderHistory); // Debugging
            })
            .addCase(editOrderStatus.rejected, (state) => {
                state.isLoader = false;
                state.isError = true;
            });
    }
});

// Export reducer
export default OrderHistorySlice.reducer;
