import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';



const TrackOrder = () => {
    const [orders, setOrders] = useState([]);
    const [currentStep, setCurrentStep] = useState(4);
    const location = useLocation();
    const product = location.state.product;

    useEffect(() => {
        const getTokenFromLocalStorage = () => localStorage.getItem("token");
        const token = getTokenFromLocalStorage();

        fetch('https://ruminate.testingruminate.co.in/public/api/orders/status/5', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setOrders(data);
                console.log("ali baba" + data)
            });
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };


    return (

        <div className="max-w-4xl mx-auto p-4 lg:p-8">
            <h1 className="text-2xl font-bold mb-4">Track Your Order</h1>
            <div className="order-card mb-8">
                <div className="flex justify-between mb-2">
                    <div className="product-info">
                        <img
                            src={product.product_image}
                            alt="Product Image"
                            className="w-24 h-24 object-cover mb-2"
                        />
                        <h2 className="text-lg font-bold">{product.productName.split(" ").slice(0, 5).join(" ")} </h2>
                        <span className="text-gray-600">add color</span>
                    </div>
                    <div className=" flex flex-col">
                        <span>Product ID: {product.id}</span>
                        <span>Seller: Add in backend</span>
                        <div className="flex">
                            <span>Amount:  </span>
                            <span className="text-blue-700 ml-2">₹{product.total}</span>
                        </div>
                        <span>Quantity: {[product.qty]}</span>
                    </div>
                </div>
            </div>
            {/* track staus  start */}
            <div className="flex justify-center mb-16">
                <div className="flex items-center w-1/2">
                    <div
                        className={`flex flex-col justify-center items-center w-4 h-4 bg-blue-400 rounded-full ${currentStep >= 1 ? 'bg-blue-600' : ''
                            }`}
                    >
                        <div className="text-center text-white"></div>
                        <div className="text-xs font-bold mt-14 text-gray-600">Ordered</div>
                    </div>
                    <div
                        className={`w-full h-1 bg-blue-200 ${orders.data && orders.data.pending === "yes" ? 'bg-blue-400' : ''
                            }`}
                    />
                    <div
                        className={`flex flex-col justify-center items-center w-4 h-4 bg-blue-400 rounded-full ${currentStep >= 2 ? 'bg-blue-600' : ''
                            }`}
                    >
                        <div className="text-center text-white"></div>
                        <div className="text-xs font-bold mt-14 text-gray-600">Pending</div>
                    </div>
                    <div
                        className={`w-full h-1 bg-blue-200 ${orders.data && orders.data.shipped === "yes" ? 'bg-blue-400' : ''
                            }`}
                    />
                    <div
                        className={`flex flex-col justify-center items-center w-4 h-4 bg-blue-400 rounded-full ${currentStep >= 3 ? 'bg-blue-600' : ''
                            }`}
                    >
                        <div className="text-center text-white"></div>
                        <div className="text-xs font-bold mt-14 text-gray-600">Shipped</div>
                    </div>
                    <div
                        className={`w-full h-1 bg-blue-200 ${orders.data && orders.data.picked === "yes" ? 'bg-blue-400' : ''
                            }`}
                    />
                    <div
                        className={`flex flex-col justify-center items-center w-4 h-4 bg-blue-400 rounded-full ${currentStep >= 4 ? 'bg-blue-600' : ''
                            }`}
                    >
                        <div className="text-center text-white"></div>
                        <div className="text-xs font-bold mt-14 text-gray-600">Picked</div>
                    </div>
                </div>
                <button onClick={() => setCurrentStep(currentStep + 1)}>Next Step</button>
            </div>
            {/* track staus end */}

            <div className="delivery-info mb-8">
                <h2 className="text-xl font-bold mb-2">Delivery Information:</h2>
                <div className="flex justify-between mb-2 line-through">
                    <span>Expected Date:</span>
                    <span className="text-red-500">{formatDate(product.created_at)}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span>Expected Date:</span>
                    <span>{formatDate(product.created_at)}</span>
                </div>
            </div>
            <div className="actions">
                <button className="bg-red-500 hover:bg-red-700 text-white p-2 rounded-lg mr-4">
                    Cancel
                </button>
                <button className="bg-blue-500 hover:bg-blue-700 text-white p-2 rounded-lg">
                    Need Help?
                </button>
            </div>
        </div>

    );
};

export default TrackOrder;