import { useEffect, useState } from "react";
import { fetchProfile } from "../../redux/Slice/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { addorder } from "../../redux/Slice/Seller/OrderSlice";
import { useNavigate } from 'react-router-dom';
import { resetCart } from "../../redux/orebiSlice";
import Swal from 'sweetalert2';
const AddressConformed = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const products = useSelector((state) => state.orebiReducer.products);
    const { profileData, profileStatus } = useSelector((state) => state.profile);
    const [Shop, setShow] = useState(true);

    useEffect(() => {
        dispatch(fetchProfile());

    }, []);

    const changeprofile = () => {
        setShow(true);
    };
    const orderConfirmed = () => {
        if (profileData.role_id == 2) {
            Swal.fire({
                title: 'Select Payment Method',
                showCancelButton: true,
                confirmButtonText: 'Online Payment',
                cancelButtonText: 'COD',
                cancelButtonColor: '#3085d6', // Change button color if needed
                reverseButtons: false, // Show "Cancel" button on the left
                // Handle button clicks
            }).then((result) => {
                if (result.isConfirmed) {

                    dispatch(addorder(products));
                    dispatch(resetCart());
                    navigate("/shop");
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    navigate("/address-confirmed");
                    dispatch(addorder(products));
                    dispatch(resetCart());
                    navigate("/shop");
                }
            });
        }
        else {

            navigate("/signup");
        }
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '600px', border: '1px solid #ccc', borderRadius: '5px', padding: '20px' }}>
                {profileStatus === "success" ? (
                    <div>
                        <h2>User Profile</h2>
                        <p>ID: {profileData.id}</p>
                        <p>Name: {profileData.name}</p>
                        <p>Email: {profileData.email}</p>
                        <p>Address: {profileData.address}</p>
                        <p>City: {profileData.city}</p>

                        {/* Change address button */}
                        <button
                            style={{
                                backgroundColor: '#007bff',
                                color: '#fff',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginBottom: '10px', // Added margin bottom for spacing
                            }}
                            onClick={changeprofile}
                        >
                            Change Address
                        </button>

                        {/* Continue button with changed color and margin */}
                        <button
                            style={{
                                backgroundColor: '#dc3545', // Changed button color to red
                                color: '#fff',
                                border: 'none',
                                padding: '10px 20px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                marginLeft: '10px', // Added margin left for spacing
                            }}
                            onClick={orderConfirmed}
                        >
                            Continue
                        </button>

                        <button
                            style={{ marginLeft: '10px' }}
                            onClick={() => dispatch(resetCart())}
                            className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300"
                        >
                            Reset cart
                        </button>
                    </div>
                ) : (
                    <p>Loading profile...</p>
                )}
            </div>
        </div>
    );
};

export default AddressConformed;
