import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";

const ProductDetails = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");
  const [productInfo, setProductInfo] = useState(null);
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    if (location.state && location.state.item) {
      setProductInfo(location.state.item);
      setMainImage(location.state.item.img); // Set initial main image
      console.log("xyz" + location.state.item.img)
      setPrevLocation(location.pathname);
    }
  }, [location]);

  if (!productInfo) {
    return <div>Loading...</div>; // Add a loading indicator if productInfo is not yet set
  }

  const changeImage = (newImage) => {
    setMainImage(newImage); // Update the main image
  };
  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="xl:-mt-10 -mt-7">
          <Breadcrumbs title="" prevLocation={prevLocation} />
        </div>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 h-full -mt-5 xl:-mt-8 pb-10 bg-gray-100 p-4">
          {/* Main product image */}
          <div className="h-full flex justify-center items-center">
            {mainImage && (
              <img
                className="object-contain max-w-full"
                style={{ height: '400px', width: '400px' }} // Set height and auto width
                src={mainImage}
                alt={mainImage}
              />
            )}
          </div>

          {/* Product information */}
          <div className="h-full flex flex-col justify-between">
            <ProductInfo productInfo={productInfo} />
          </div>

          {/* Additional product images */}
          <div className="h-full flex flex-col">
            <div className="flex justify-between">
              <div className="w-1/3">
                {productInfo.img1 && (
                  <img
                    className="object-contain max-w-full cursor-pointer"
                    style={{ height: '100px', width: 'auto' }} // Set fixed height and auto width
                    onClick={() => changeImage(productInfo.img1)}
                    src={productInfo.img1}
                    alt={productInfo.img1}
                  />
                )}
              </div>
              <div className="w-1/3">
                {productInfo.img2 && (
                  <img
                    className="object-contain max-w-full cursor-pointer"
                    style={{ height: '100px', width: 'auto' }} // Set fixed height and auto width
                    onClick={() => changeImage(productInfo.img2)}
                    src={productInfo.img2}
                    alt={productInfo.img2}
                  />
                )}
              </div>
              <div className="w-1/3">
                {productInfo.img3 && (
                  <img
                    className="object-contain max-w-full cursor-pointer"
                    style={{ height: '100px', width: 'auto' }} // Set fixed height and auto width
                    onClick={() => changeImage(productInfo.img3)}
                    src={productInfo.img3}
                    alt={productInfo.img3}
                  />
                )}
              </div>
              <div className="w-1/3">
                {productInfo.img3 && (
                  <img
                    className="object-contain max-w-full cursor-pointer"
                    style={{ height: '100px', width: 'auto' }} // Set fixed height and auto width
                    onClick={() => changeImage(productInfo.img)}
                    src={productInfo.img}
                    alt={productInfo.img}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
